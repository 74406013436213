@import url(https://fonts.googleapis.com/css2?family=IM+Fell+Great+Primer&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IM+Fell+Great+Primer&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Days+One&display=swap);
* {
  font-family: 'IM Fell Great Primer', serif;
}

h1 {
  font-size: 20px;
}

.name {
  font-size: 32px;
  margin: 0;
}

.text {
  margin: 0;
  font-size: 18px;
}

.pronounce {
  font-size: 22.5px;
}

a {
  color: black;
}
.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 80vh;
  width: 310px;
  margin: 0 auto;
}

.definition {
  width: auto;
  margin: 0 auto;
}

.pageContainer {
  width: auto;
  max-width: 1000px;
  margin: 0 auto;
}
.footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  margin: 0;
}


@media only screen and (min-width: 0px) and (max-width: 800px)
{
  .pageContainer {
    width: 95%;
  }

  .center-screen {
    width: 280px;
  }

  h1 {
    font-size: 18px;
  }
  
  .name {
    font-size: 28px;
    margin: 0;
  }
  
  .text {
    margin: 0;
    font-size: 16px;
  }
  
  .pronounce {
    font-size: 18px;
  }


  .center-screen {
    min-height: 70vh;
  }
}

@keyframes fadeIn {
  0% {
      opacity: 0;
      
    }
   
    100% {
      opacity: 1;
      
    }
}

* {
  font-family: 'Days One', sans-serif;
}

#submit {
  background-color: transparent;
}

body {
background-size: cover;
background-repeat: no-repeat;
background-attachment: scroll;

background-color: #FFFFEA;
}

.full-center {
  width: 100%;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.under-center {
  width: 100%;
  margin: 0;
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -0%);
}

.navigationWrapper {

}

.pageContainer {
  width: 100%;
  min-width: 100%;
  
  padding: 0;
  margin: 0;
  margin: 0 auto;
  min-height: 100vh;
  height: auto;
  animation: 3s ease 0s normal forwards 1 fadeIn;
    opacity: 0;
  
}

.logo {
  width: 25%;
}







h1 {
  font-size: 2em;
  margin: 0px;
}

p {
  font-size: 1.5em;
  margin: 0px;
}

h2 {
  font-size: 1em;
  margin: 0px;
}

.specialDiv {
  width: 50%;
}

.oneLine {
  font-size: 1.2em;
}

.arrow {
  width: 5%;
}

.rest-of-content {
  width: 100%;
  margin: 0;
  position: absolute;
  top: 105%;
  left: 50%;
  transform: translate(-50%, 0%);
}

.twitterFeed {
  width: 30%;
}

.click {
  width: 25%;
  z-index: -1;
  
}

h3 {
  font-size: 1.25em;
  text-align: left;
}

h4 {
  font-size: 1em;
  text-align: left;

}


.memsDoingThings {
  width: 100%;
  position: relative;
  /* left: 50%; */
  /*transform: translate(-50%, 0%);*/
  z-index: 999;
  pointer-events: none;
}

.footerLogo {
  width: 100%;
  /*position: absolute;
  transform: translate(-50%, 180%);
  z-index: -1;*/
}


.customPlayer {
  margin-top: 2em;
  margin-bottom: 2em;
  width: 40%;
  background-color: transparent;
  z-index: -1;
}

.customPlayerButton {
  width: 25%;
}

.navigationContainer {
  margin-top: 1.5em;
  width: 45%;
  display: flex;
}

.navigationheader {
  width: 30%;
}

.block {
  margin: 0;
  width: 33%;


}
.logo {
  width: 20%;
}

.header {
  width: 20%;
}

.submitBtn {
  border: 'none';
  outline: 0;
  border-style: none; outline-style: none;
  width: 10%;
}

.ar {
  width: 15%;

}

.virtual {
  width: 20%;
}



.inputForm {
  width: 15%;
  height: 2em;
}


@media only screen and (min-width: 0px) and (max-width: 700px)
{

  .logo {
    width: 70%;
  }

  .header {
    width: 40%;
  }

  .ar {
    width: 40%;
  }

  .virtual {
    width: 50%;
  }
  .inputForm {
    width: 40%;

  }
  

  .submitBtn {
    width: 25%;
  }



  .navigationContainer {
    width: 100%;
    display: inline-block;
  }

  .navigationHeader {
    width: 33%;
  }

  .customPlayer {
    margin-top: 2em;
    margin-bottom: 2em;
    width: 80%;
    background-color: transparent;
  }
  
  .customPlayerButton {
    width: 18%;
  }
  

  .specialDiv {
    width: 100%;
  }

  .oneLine {
    font-size: 1em;
  }
  

  .pageContainer {
    width: 100%;
    padding: 0;
    margin: 0;
    max-width: 100%;
    margin: 0 auto;
    min-height: 100vh;
    height: auto;
    animation: 3s ease 0s normal forwards 1 fadeIn;
    
  }
  
  
  
  h1 {
    font-size: 1.5em;
  }
 
  p {
    font-size: 1.25em;
  }

  h2 {
    font-size: 1em;
  }

  .arrow {
    width: 20%;
  }

  .click {
    width: 30%;
  }

  .under-center {
    width: 100%;
    margin: 0;
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: 999;
  }

  .rest-of-content {
    width: 100%;
    margin: 0;
    position: absolute;
    top: 105%;
    left: 50%;
    transform: translate(-50%, 0%);
  }

  .twitterFeed {
    width: 70%;
  }

  

  


}
/*
@media only screen and (min-width: 600px) and (max-width: 1000px)
{
  .specialDiv {
    width: 100%;
  }

  .oneLine {
    font-size: 1em;
  }
  

  .pageContainer {
    width: 100%;
    padding: 0;
    margin: 0;
    max-width: 100%;
    margin: 0 auto;
    min-height: 100vh;
    height: auto;
    -webkit-animation: 3s ease 0s normal forwards 1 fadeIn;
      animation: 3s ease 0s normal forwards 1 fadeIn;
    
  }
  
  .logo {
    width: 70%;
  }
  
  h1 {
    font-size: 1.5em;
  }
 
  p {
    font-size: 1.25em;
  }

  h2 {
    font-size: 1em;
  }

  .arrow {
    width: 10%;
  }

  .click { 
    width: 30%;
  }

  .under-center {
    width: 100%;
    margin: 0;
    position: absolute;
    top: 85%;
    left: 50%;
    transform: translate(-50%, -0%);
  }

  .twitterFeed {
    width: 50%;
  }

  .memsDoingThings {
    width: 50%;
    position: absolute;
    /* left: 50%; 
    transform: translate(-50%, -10%);
  }
  
  .footerLogo {
    width: 50%;
    position: absolute;
    transform: translate(-50%, 90%);

  }

  

}
*/

